/* eslint-disable */
// import fetch from 'dva/fetch';
// import { routerRedux } from 'dva/router';
import axios from 'axios'
// import { notification, message } from 'antd'
import {
  message
} from 'element-ui'
import qs from 'qs'

axios.defaults.timeout = 30000
axios.defaults.withCredentials = false
let loadingCount = 0
const loginFlag = 0
// let loading;
// eslint-disable-next-line no-undef
// const userInfo = connect({ login }) => ({...login});
// const startLoading = () => {
//   loading = Loading.service({
//     lock: true,
//     text: '加载中……',
//     background: 'rgba(0, 0, 0, 0.7)'
//   })
// };

// const endLoading = () => {
//   // loading.close();
// };

export const showLoading = () => {
  if (loadingCount === 0) {
    // startLoading();
  }
  // 开启loading 并loadingCount+1
  loadingCount += 1
}

export const hideLoading = () => {
  if (loadingCount <= 0) {
    return
  }
  loadingCount -= 1
  // loadingCount--直到loading的请求都结束了再关闭loading窗口
  if (loadingCount === 0) {
    // endLoading();
  }
}

// eslint-disable-next-line no-unused-vars
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  message({
    message: `请求错误 ${response.status}: ${response.url}`,
    type: 'error'
  })
  // notification.error({
  //   message: `请求错误 ${response.status}: ${response.url}`,
  //   description: response.statusText
  // })
  const error = new Error(response.statusText)
  error.response = response
  throw error
}

axios.interceptors.response.use(res => {
  const {
    data
  } = res || {}
  return res.data
})

export default {
  async post(url, params, useForm = false, test, webIm, requestUrl) {
    return request('post', url, params, useForm, test, webIm, requestUrl)
  },
  async put(url, params, useForm = true) {
    return request('put', url, params, useForm)
  },
  async get(url, params = {}, useForm = true, test, msg, requestUrl) {
    return request('get', url, params, useForm, test, msg, requestUrl)
  },
  async delete(url = '', params, useForm = true) {
    return request('delete', url, params, useForm)
  }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
async function request(method, url = '', params, useForm = true, test, msg, requestUrl) {
  if (requestUrl) {
    // eslint-disable-next-line no-console
    console.log('test')
  }
  // urlM = 'https://test.zhiyunduan.cn';
  // port = ':8095';

  //const baseUrl = 'https://test.zhiyunduan.cn/website-api'
  //  const baseUrl = 'http://www.zhiyunduan.cn:9090/website'
  // const baseUrl = 'https://tytest.zhiyunduan.cn:1234/website-api' // 迁移后的地址
  const baseUrl = 'https://website.zhiyunduan.cn/website-api'
  const token = ''


  const requestParams = Object.assign(params || {}, {})
  // eslint-disable-next-line max-len
  // const requestParams = Object.assign(params, { serialNumber, serialNum, role: 'WEB', appId: '6666' });
  if (params.respId) {
    requestParams.userId = params.respId
  }
  // eslint-disable-next-line no-param-reassign
  url = baseUrl + url
  // headers['Content-type'] = 'application/x-www-form-urlencoded'
  //  写入请求头类型
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*'
  }
  if (token) {
    header.Authorization = `Bearer ${token}`
  }
  const options = {
    headers: header,
    method,
    url
  }

  if (useForm) {
    options.params = requestParams
  } else {
    options.data = qs.stringify(requestParams)
  }
  return axios(options)
}

/**
 * response拦截器
 * @param {*} res
 */

function parseResponse(res) {
  if (res.data.code && typeof res.data.code === 'string') { // 标准接口
    if (res.data.code === '0000') {
      return [res.data, true]
    } else if (res.data.code.substr(0, 1) === '5') {
      const resErr = {
        msg: res.data.respMsg
      }
      const msg = res.data.msg || res.data.respMsg
      message.error(msg || '网络错误') // 全局弹窗 错误提示
      return Promise.reject(resErr)
    } else if (res.data.code === 200) {
      return [res.data, true]
    } else {
      const msg = res.data.msg || res.data.respMsg
      message.error(msg || '网络错误') // 全局弹窗 错误提示
      const errRes = {
        code: res.data.code,
        msg,
        data: res.data
      }
      if (res.data.code) {
        return [res.data, false]
      } else {
        return Promise.reject(errRes)
      }
    }
  } else { // 非标准接口
    // eslint-disable-next-line no-lonely-if
    if (res.statusCode === 200 || res.status === 200) {
      if (res.data.code === 500) {
        // 全局弹窗
        const msg = res.data.errorMsg || res.data.msg
        message.error(msg || '网络错误') // 全局弹窗 错误提示
        return [res.data, false]
      } else if (res.data.respCode === 200) {
        return [res.data, true]
      } else if (res.data.respCode === 400) {
        const msg = res.data.errorMsg || res.data.msg
        message.error(msg || '网络错误') // 全局弹窗 错误提示
        return [res.data, false]
      } else {
        const msg = res.data.errorMsg || res.data.msg
        message.error(msg || '网络错误') // 全局弹窗 错误提示
        return [res.data, false]
      }
    } else if (res.data.error && res.data.error === 'invalid_token') {
      // location.reload(); // token失效重新登录  && url !== '/api/auth/oauth/logout'
    } else {
      return Promise.reject(res)
    }
  }
}
